import { Entity } from '../abstract/entity';

export class Patient extends Entity {
  constructor(entityId: number, obj?: Partial<Patient>) {
    super(entityId);
    if (obj) {
      Object.assign(this, obj);
    }
  }

  name?: string
  gender?: string;
  age?: string;
  organisationPatientId?: string
}
