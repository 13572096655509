import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';
import { BaseService } from 'src/app/shared/based.service';
import { Apollo, gql } from 'apollo-angular';
import { Report } from '../domain/entities/report';
import { IReportService } from '../domain/services/ireport.service';
import { StorageService } from 'src/app/shared/storage/storage.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Vitals } from '../domain/entities/vitals';
import { PatientDetails } from '../domain/entities/patientDetails';
import { Flags } from '../domain/entities/flags';
import { Medication } from '../domain/entities/medication';
import { Progress } from '../domain/entities/progress';
import { Survey } from '../domain/entities/survey';
import { Comment } from '../domain/entities/comment';
import { Organization } from "src/app/admin-panel/report/domain/entities/organization";
import { Summary } from '../domain/entities/summary';
import { Recording } from '../domain/entities/recording';

@Injectable()
export class ReportService extends BaseService implements IReportService {
  constructor(
    _storageService: StorageService,
    private _apollo: Apollo,
    private _http: HttpClient
  ) {
    super(_storageService);
  }
  private pathAPI = environment.apiUrl;

  getReports(): Observable<Report[]> {
    const GET_REPORTS = gql`
      query GetReports {
        ecgReport {
          entityId: ecgReportId
          deviceNumber
          aiResponse
          fileName
          reportedAt
          risk
          bpm
          qrs
          qt
          qtc
          pr
          st
          findings
          interpretation
          recommendation
          createdAt
          setup
          deviceVersion
          patient {
            entityId: patientId
            gender
            age
            name
            organisationPatientId
          }
          organization {
            entityId: organizationId
            name
            email
            phoneNumber
            countryCode
            registeredNumber
            street
            city
            state
            country
            totalECGCount
            noActionCount
            urgentActionCount
            actionRequiredCount
            stemiPreference
            printAi
          }

          referredById
          referredBy {
           name
           qualification
           qualification
         }

          reportedById
          reportedBy {
            entityId: reportedById
            name
            qualification
            mciNumber
            canPrintMCIId
            addSignature
          }

          takenBy {
            entityId: takenById
            name
            phoneNumber
            countryCode
            street
            street
            state
            city
            country
          }
        }
      }
    `;
    return this._apollo
      .query<any>({
        query: GET_REPORTS,
      })
      .pipe(
        map((res) => {
          const reports = res.data.ecgReport.map(
            (x: Partial<Report>) => new Report(x.entityId!, x)
          );
          return reports;
        })
      )
      .pipe(catchError(this.handleError));
  }

  getPatientDetail(patientId: any): Observable<PatientDetails> {
    return this._http
      .get<any>(this.pathAPI + `Patient/${patientId}`, super.headers())
      .pipe(
        map((res) => {
          return res;
        })
      )
      .pipe(catchError(this.handleError));
  }

  getPatientVitals(recordingId: any): Observable<Vitals> {
    return this._http
      .get<any>(this.pathAPI + `Case/Vitals/${recordingId}`, super.headers())
      .pipe(
        map((res) => {
          return res;
        })
      )
      .pipe(catchError(this.handleError));
  }

  getFlags(recordingId: any): Observable<Flags> {
    return this._http
      .get<any>(this.pathAPI + `Case/Flags/${recordingId}`, super.headers())
      .pipe(
        map((res) => {
          return res;
        })
      )
      .pipe(catchError(this.handleError));
  }

  getMedication(recordingId: any): Observable<Medication> {
    return this._http
      .get<any>(
        this.pathAPI + `Case/Medications/${recordingId}`,
        super.headers()
      )
      .pipe(
        map((res) => {
          const medication = new Medication(res.id, res);
          return medication;
        })
      );
  }

  getProgress(recordingId: any): Observable<Progress[]> {
    return this._http
      .get<any>(this.pathAPI + `Case/Progress/${recordingId}`, super.headers())
      .pipe(
        map((res) => {
          return res;
        })
      )
      .pipe(catchError(this.handleError));
  }

  getSurvey(recordingId: any): Observable<Survey> {
    return this._http
      .get<any>(
        this.pathAPI + `Case/FAQs/${recordingId}`,
        super.headers()
      )
      .pipe(
        map((res) => {

          return res;
        })
      )
      .pipe(catchError(this.handleError));
  }

  getCommentMessage(recordingId: any): Observable<Comment[]> {
    return this._http.get<any>(this.pathAPI + `Case/Message/${recordingId}`, super.headers()).pipe(map((res) => {
      return res
    }))
  }

  addCommentMessage(recordingId: any, message: Comment): Observable<Comment> {
    return this._http.post<Comment>(this.pathAPI + `Case/AddMessage/${recordingId}`, message, super.headers()).pipe(catchError(this.handleError))
  }

  getOrganization(): Observable<Organization[]> {
    const GET_ORGANIZATION = gql`
    query GetOrganization {
      organization {
      hubId
      entityId: organizationId
      planType
      stemiPreference
      }
    }
    `;
    return this._apollo
      .query<any>({
        query: GET_ORGANIZATION,
      })
      .pipe(
        map((res) => {
          return res.data.organization.map(
            (x: Partial<Organization>) => new Organization(x.entityId!, x)
          )
        }
        )
      )
      .pipe(catchError(this.handleError));
  }

  getSummaryDetail(recordingId: any): Observable<Summary> {
    return this._http.get<any>(this.pathAPI + `Case/Summary/${recordingId}`, super.headers()).pipe(map((res) => {
      return res
    }))
  }

  getECGRecording(recordingId: any): Observable<Recording> {
    return this._http.get<any>(this.pathAPI + `ECGRecording/${recordingId}`, super.headers()).pipe(map((res) => {
      const reccording = new Recording(res.id, res)
      return reccording
    }))
  }

  getFilteredRecording(organizationId?: any, risk?: any, dateRange?: any): Observable<Report[]> {

    var queryData = ''
    var variables: Variable = {
      riskIn: [],
      gte: '',
      lte: '',
      organizationIds: []
    }
    var queryParameter = ''

    if (risk.length > 0) {
      queryData = 'risk:{in:$riskIn}'
      variables.riskIn = risk
      queryParameter = '$riskIn: [String],'
    }
    if ((dateRange.startDate != '' && dateRange.startDate) && (dateRange.endDate != '' || dateRange.endDate)) {
      queryData += 'createdAt:{ gte: $gte, lte: $lte }'
      variables.gte = dateRange.startDate
      variables.lte = dateRange.endDate
      queryParameter += '$gte: DateTime, $lte: DateTime,'
    }

    if (organizationId.length > 0) {
      queryData += 'organizationId:{in:$organizationIds}'
      variables.organizationIds = organizationId
      queryParameter += '$organizationIds: [Int!],'
    }

    const GET_FILTERED_REPORT = gql`
    query GetEcgReports(${queryParameter}) {
      ecgReport(where: {${queryData}}){
        entityId: ecgReportId
        deviceNumber
        reportedAt
        risk
        createdAt
        patient {
          entityId: patientId
          gender
          age
        }
        organization {
          entityId: organizationId
          name
          street
          city
          state
        }
        reportedBy {
          entityId: reportedById
          name
          qualification
          mciNumber
          canPrintMCIId
          addSignature
        }
      }
    }`

    return this._apollo
      .query<any>({
        query: GET_FILTERED_REPORT,
        variables: variables
      })
      .pipe(
        map((res) => {
          const reports = res.data.ecgReport.map(
            (x: Partial<Report>) => new Report(x.entityId!, x)
          );
          return reports;
        })
      )
      .pipe(catchError(this.handleError));
  }
}


export interface Variable {
  riskIn: string[]
  gte: string
  lte: string
  organizationIds: number[]
}