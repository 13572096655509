import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRoute,
  RoutesRecognized,
  NavigationEnd,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { filter, map } from 'rxjs';

@Injectable()
export class AppSharedService {
  activeMenu: string = '';

  constructor(private router: Router, private route: ActivatedRoute) {
    this.routeConfig();
  }

  routeConfig() {
    this.router.events.subscribe((e) => {
      if (e instanceof RoutesRecognized) {
        this.router.events
          .pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => this.route.snapshot),
            map((route) => {
              while (route.firstChild) {
                route = route.firstChild;
              }
              return route;
            })
          )
          .subscribe((route: ActivatedRouteSnapshot) => {
            const data = route.data;
            if (data['activeMenu']) {
              this.activeMenu = data['activeMenu'];
            }
          });
      }
    });
  }
}
