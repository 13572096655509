import { Organization } from './allorganization';
import { GraphData } from './graphdata';
import { PatientInformation } from './patient-information';

export class Dashboard {
  constructor(obj?: Partial<Dashboard> | undefined) {
    if (obj) {
      Object.assign(this, obj);

      this.organizations = [];
      const organizations = obj.organizations?.map(
        (organization) => new Organization(organization.entityId, organization)
      );
      if (organizations) {
        this.organizations = organizations;
      }
    }
  }

  totalEcgRecorded?: string;
  noActionCount?: number;
  urgentActionCount?: number;
  actionRequiredCount?: number;
  organizations?: Organization[];
  graphData?: GraphData[];
  averageReportTimeDifference?: number
  patientInformation?: PatientInformation;

  get formattedAverageReportDifference() {
    if (this.averageReportTimeDifference) {
      return this.averageReportTimeDifference.toFixed()
    } else {
      return 0
    }
  }
}
