import { Entity } from '../abstract/entity';
import { Organization } from './organization';
import { Patient } from './patient';
import { ReferredBy } from './referredBy';
import { ReportedBy } from './reportedBy';

export class Report extends Entity {
  constructor(entityId: number, obj?: Partial<Report>) {
    super(entityId);
    if (obj) {
      Object.assign(this, obj);

      if (obj.organization && obj.organization.entityId) {
        this.organization = new Organization(
          obj.organization.entityId,
          obj.organization
        );
      }

      if (obj.patient && obj.patient.age) {
        this.patient = new Patient(obj.patient.entityId, obj.patient);
      }

      if (obj.reportedBy && obj.reportedBy.name) {
        this.reportedBy = new ReportedBy(
          obj.reportedBy.entityId,
          obj.reportedBy
        );
      }

      if (obj.takenBy && obj.takenBy.name) {
        this.takenBy = new ReportedBy(
          obj.takenBy.entityId,
          obj.takenBy
        );
      }
    }
  }

  name?: string;
  options?: string[];
  defaultValue?: string;
  deviceNumber?: string;
  fileName?: string;
  reportedAt?: string;
  risk?: string;
  bpm?: number;
  qrs?: number;
  qt?: number;
  qtc?: number;
  pr?: number;
  st?: number;
  createdAt?: Date
  findings?: string;
  interpretation?: string;
  recommendation?: string;
  organization?: Organization;
  patient?: Patient;
  reportedBy?: ReportedBy;
  takenBy?: ReportedBy;
  referredById?: number;
  referredBy?: ReferredBy;
  reportedById?: number
  aiResponse?: string
  setup?: string
  deviceVersion?: string
}
