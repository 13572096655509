import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { throwError } from 'rxjs';
import { StorageService } from './storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  constructor(protected _storageService: StorageService) {}

  public handleError(error: HttpErrorResponse | any) {
    let errMsg: any;

    if (error instanceof HttpErrorResponse) {
      const body = error.error || '';

      const err = body || JSON.stringify(body);

      errMsg = err.text;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }

    if (!errMsg) {
      if (error.status == 401) {
        errMsg = 'Unauthorized user found.';
      }
    }
    return throwError(() => new Error(errMsg));
  }

  protected headers() {
    let header = new HttpHeaders({ 'Content-Type': 'application/json' });
    // header = header.append('apiKey', environment.apiKey);
    // header = header.append('apiPassword', environment.apiPassword);

    if (this._storageService.isAuthenticated()) {
      header = header.append(
        'Authorization',
        'Bearer ' + this._storageService.getToken()
      );
    }

    return { headers: header };
  }
}
