import { Observable } from "rxjs";
import { SASToken } from "../entities/sas";

export interface ITokenService {
    getReadTokenForSignature(id: number): Observable<SASToken>
    getReadTokenForECG(fileName: string): Observable<SASToken>
    getReadTokenForOrganizationLogo(): Observable<SASToken>
}

export abstract class AbstractTokenService implements ITokenService {
    abstract getReadTokenForSignature(id: number): Observable<SASToken>
    abstract getReadTokenForECG(fileName: string): Observable<SASToken>
    abstract getReadTokenForOrganizationLogo(): Observable<SASToken>
}

