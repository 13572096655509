import { Observable } from "rxjs";
import { Report } from "../entities/report";
import { Vitals } from "../entities/vitals";
import { PatientDetails } from "../entities/patientDetails";
import { Flags } from "../entities/flags";
import { Medication } from "../entities/medication";
import { Progress } from "../entities/progress";
import { Survey } from "../entities/survey";
import { Comment } from "../entities/comment";
import { Organization } from "src/app/admin-panel/report/domain/entities/organization";
import { Summary } from "../entities/summary";
import { Recording } from "../entities/recording";

export interface IReportService {
  getReports(): Observable<Report[]>;
  getPatientDetail(patientId: any): Observable<PatientDetails>;
  getPatientVitals(recordingId: any): Observable<Vitals>;
  getFlags(recordingId: any): Observable<Flags>;
  getMedication(recordingId: any): Observable<Medication>;
  getProgress(recordingId: any): Observable<Progress[]>;
  getSurvey(recordingId: any): Observable<Survey>;
  getCommentMessage(recordingId: any): Observable<Comment[]>
  addCommentMessage(recordingId: any, message: Comment): Observable<Comment>
  getOrganization(): Observable<Organization[]>
  getSummaryDetail(recordingId: any): Observable<Summary>;
  getECGRecording(recordingId: any): Observable<Recording>
  getFilteredRecording(organizationId?: any, risk?: any, dateRange?: any): Observable<Report[]>
}

export abstract class AbstractReportService implements IReportService {
  abstract getReports(): Observable<Report[]>;
  abstract getPatientDetail(patientId: any): Observable<PatientDetails>;
  abstract getPatientVitals(recordingId: any): Observable<Vitals>;
  abstract getFlags(recordingId: any): Observable<Flags>;
  abstract getMedication(recordingId: any): Observable<Medication>;
  abstract getProgress(recordingId: any): Observable<Progress[]>;
  abstract getSurvey(recordingId: any): Observable<Survey>;
  abstract getCommentMessage(recordingId: any): Observable<Comment[]>
  abstract addCommentMessage(recordingId: any, message: Comment): Observable<Comment>
  abstract getOrganization(): Observable<Organization[]>
  abstract getSummaryDetail(recordingId: any): Observable<Summary>;
  abstract getECGRecording(recordingId: any): Observable<Recording>
  abstract getFilteredRecording(organizationId?: any, risk?: any, dateRange?: any): Observable<Report[]>
}