import { Observable } from 'rxjs';
import { Dashboard } from '../entities/dashboard';
import { EcgDeviceLocationDetail } from '../entities/ecg-device-location-detail';
import { Organization } from '../entities/allorganization';
import { SASToken } from 'src/app/admin-panel/report/domain/entities/sas';

export interface IDashboardService {
  getDashboard(startDate?: string, endDate?: string): Observable<Dashboard>;
  hubECGDeviceLocationDetails(): Observable<EcgDeviceLocationDetail[]>
  getOrganizations(startDate?: string, endDate?: string): Observable<Organization[]>
  getReadTokenForJSPDF(): Observable<SASToken>
}

export abstract class AbstractDashboardService implements IDashboardService {
  abstract getDashboard(startDate?: string, endDate?: string): Observable<Dashboard>;
  abstract hubECGDeviceLocationDetails(): Observable<EcgDeviceLocationDetail[]>
  abstract getOrganizations(startDate?: string, endDate?: string): Observable<Organization[]>
  abstract getReadTokenForJSPDF(): Observable<SASToken>
}
