import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BaseService } from 'src/app/shared/based.service';
import { StorageService } from 'src/app/shared/storage/storage.service';
import { environment } from 'src/environments/environment';


@Injectable()
export class BlobService extends BaseService {

  constructor(private http: HttpClient, _storageSerivce: StorageService) {
    super(_storageSerivce);
  }




  getECGRecording(uuid: string, sasToken: string): Observable<ArrayBuffer> {
    let headers = new HttpHeaders({ 'Content-Type': 'text/plain', 'Accept': 'text/plain', 'x-ms-blob-type': 'BlockBlob', 'x-ms-range': 'bytes=0-80000' });
    return this.http.get(environment.azureBaseUrl + `ecg-recordings/${uuid}${sasToken}`, { headers: headers, responseType: 'arraybuffer' })
      .pipe(catchError(this.handleError));
  }

}
