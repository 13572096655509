import { Entity } from '../abstract/entity';

export class EcgDeviceLocationDetail extends Entity {
    constructor(entityId: string, obj?: Partial<EcgDeviceLocationDetail>) {
        super(entityId);
        if (obj) {
            Object.assign(this, obj);
        }
    }

    actionRequiredCount?: number;
    urgentActionCount?: number;
    longitude?: number;
    latitude?: number;
    organizationId?: number
    hubId?: number
}
