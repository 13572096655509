import { Entity } from '../abstract/entity';

export class ReportedBy extends Entity {
  constructor(entityId: number, obj?: Partial<ReportedBy>) {
    super(entityId);
    if (obj) {
      Object.assign(this, obj);
    }
  }

  name?: string;
  qualification?: string;
  mciNumber?: string;
  canPrintMCIId?: boolean;
  addSignature?: boolean;
  countryCode?: number
  phoneNumber?: string
  firstName?: string
  lastName?: string

  get formattedPhoneNumberForTakenBY(): string {
    if (this.phoneNumber) {
      return '+' + this.countryCode + '.' + this.phoneNumber?.substring(0, 5) + '.' + this.phoneNumber?.substring(5)
    } else {
      return ''
    }
  }
}
