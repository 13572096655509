import { Entity } from '../abstract/entity';

export class Recording extends Entity {
  constructor(entityId: number, obj?: Partial<Recording>) {
    super(entityId);
    if (obj) {
      Object.assign(this, obj);
    }
  }

  id?: number
  patient?: {
    id: 1472
    phoneNumber?: string
    countryCode?: number
    patientFirstName?: string
    patientLastName?: string
    patientDateOfBirth?: string
    patientGender?: string
  }
  acceptedByDoctor?: {
    id?: 70,
    firstName?: string
    lastName?: string
    qualification?: string
    phoneNumber?: string
    countryCode?: number
    canPrintMCIId?: boolean
    mciNumber?: string
  }
  organisation?: {
    id?: number
    firstName?: string
    lastName?: string
    phoneNumber?: string
    countryCode?: number
    city?: string
    state?: string
  }
  fileName?: string
  reason?: string
  reviewStatus?: string
  createdAt?: string
  bpm?: number
  hasReported?: boolean
  ecgFindings?: string
  recommendations?: string
  interpretations?: string
  addSignature?: boolean
  setup?: string
  pendingApproval?: boolean
  forwardCount?: number
  reportedById?: number
  risk?: string
  qrs?:number
  st?: number
  pr?: number
  qt?: number
  qtc?: number
  caseStatus?: string
  reportedBy?: {
    id?: number
    firstName?: string
    lastName?: string
    qualification?: string
    phoneNumber?: string
    countryCode?: number
    canPrintMCIId?: boolean
    mciNumber?: string
  }
  takenBy?: {
    id?: number
    firstName?: string
    lastName?: string
    phoneNumber?: string
    countryCode?: number
  }
  organisationPatientId?: string
  oneUser?: boolean


get formattedPhoneNumber(): string {
    if (this.organisation?.phoneNumber) {
      return '+' + this.organisation?.countryCode + '.' + this.organisation?.phoneNumber?.substring(0, 5) + '.' + this.organisation?.phoneNumber?.substring(5);
    } else {
      return '';
    }
  }

get formattedPhoneNumberForTakenBY(): string {
    if(this.takenBy?.phoneNumber){
        return '+' + this.takenBy?.countryCode + '.' + this.takenBy?.phoneNumber?.substring(0,5) + '.' + this.takenBy?.phoneNumber?.substring(5)
    }else{
        return ''
    }
}
}