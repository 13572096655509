import {
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { NotifyService } from './shared/notify.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { AppSharedService } from './shared/shared.service';
import { NgxHttpLoaderModule } from 'ngx-http-loader';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ReactiveFormsModule } from '@angular/forms';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { GraphQLModule } from './graphql.module';
import { MatSortModule } from '@angular/material/sort';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxPaginationModule,
    HttpClientModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    NgxHttpLoaderModule.forRoot(),
    Ng2SearchPipeModule,
    GraphQLModule,
    MatSortModule,
  ],
  providers: [NotifyService, AppSharedService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule {
  constructor() { }
}
