<div class="container-fluid">
    <div class="header pb-2 mb-4">
        <div class="d-flex flex-row justify-content-center">
            <div class="col-11">
                <div class="headerName my-5">
                    {{recording?.organization?.name}}
                </div>
                <div class="d-flex flex-row mt-4">
                    <button style="cursor: pointer;" (click)="goBack()" class="back">
                        < back </button>
                </div>
            </div>
        </div>
    </div>
    <div>
        <div class="d-flex flex-row justify-content-center">
            <div class="col-lg-10 col-sm-12 col-md-12" style="height: 75vh;">
                <embed id="pdf-summary-report" style="height: 100%; width: 100%" />
            </div>
        </div>
    </div>
</div>