import { Entity } from '../abstract/entity';
import { HubData } from './hubData';

export class Organization extends Entity {
  constructor(entityId: string, obj?: Partial<Organization>) {
    super(entityId);
    if (obj) {
      Object.assign(this, obj);
    }
  }

  name?: string;
  totalECGCount?: number;
  actionRequiredCount?: number;
  urgentActionCount?: number;
  lastMonthCount?: number;
  currentMonthCount?: number;
  distanceFromHub?: string
  organizationId?: number
  hubList?: HubData[]

  get percent() {
    if (this.lastMonthCount ?? 0 >= 0) {
      return (
        (((this.currentMonthCount ?? 0) - (this.lastMonthCount ?? 0)) /
          (this.lastMonthCount ?? 1)) *
        100
      );
    } else {
      return 0;
    }
  }
}
