import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilterReportListService {

  constructor() { }

  private filterValue = new BehaviorSubject<any>([])
  getFilterValue = this.filterValue.asObservable()
  sendFilterValue(value:any){
    this.filterValue.next(value)
  }
}
