import { Entity } from '../abstract/entity';

export class Medication extends Entity {
  constructor(entityId: number, obj?: Partial<Medication>) {
    super(entityId);
    if (obj) {
      Object.assign(this, obj);
    }
  }

  medicine?: {
    [key:string]:string
  }
  agent?: string
  ecgRecordingId?: number
  memberId?: number
  id?: number
  createdAt?: string
  updatedAt?: string
}