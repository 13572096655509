import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { SummaryPageComponent } from './summary-page/summary-page.component';
import { AbstractReportService } from './admin-panel/report/domain/services/ireport.service';
import { ReportService } from './admin-panel/report/infrastructure/report.service';
import { AbstractBlobService } from './admin-panel/report/domain/services/iblob.service';
import { AbstractTokenService } from './admin-panel/report/domain/services/itoken.service';
import { BlobService } from './admin-panel/report/infrastructure/blob.service';
import { TokenService } from './admin-panel/report/infrastructure/token.service';
import { FilterReportListService } from './service/filter-report-list.service';
import { AbstractDashboardService } from './admin-panel/dashboard/domain/services/idashboard.service';
import { DashboardService } from './admin-panel/dashboard/infrastructure/dashboard.service';

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'pre-login',
  //   pathMatch: 'full'
  // },

  {
    path: 'admin',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('src/app/admin-panel/admin-panel.module').then(
        (m) => m.AdminPanelModule
      ),
  },
  {
    path: '',

    loadChildren: () =>
      import('./pre-login/pre-login.module').then((m) => m.PreLoginModule),
  },

  {
    path: 'summary-report/:id',
    component: SummaryPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  providers: [
    {
      provide: AbstractReportService,
      useClass: ReportService,
    },
    {
      provide: AbstractBlobService,
      useClass: BlobService,
    },
    {
      provide: AbstractTokenService,
      useClass: TokenService,
    },
    FilterReportListService,
    {
      provide: AbstractDashboardService,
      useClass: DashboardService,
    },
  ]
})
export class AppRoutingModule { }
