import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';
import { BaseService } from 'src/app/shared/based.service';
import { Apollo, gql } from 'apollo-angular';
import { StorageService } from 'src/app/shared/storage/storage.service';
import { Dashboard } from '../domain/entities/dashboard';
import { IDashboardService } from '../domain/services/idashboard.service';
import { EcgDeviceLocationDetail } from '../domain/entities/ecg-device-location-detail';
import { Organization } from '../domain/entities/allorganization';
import { SASToken } from '../../report/domain/entities/sas';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class DashboardService extends BaseService implements IDashboardService {
  constructor(_storageService: StorageService,
    private _apollo: Apollo,
    private _http: HttpClient) {
    super(_storageService);
  }

  private pathAPI = environment.apiUrl

  getDashboard(startDate?: string, endDate?: string): Observable<Dashboard> {
    const GET_DASHBOARD = gql`
      query Dashboard($startDate: DateTime, $endDate: DateTime) {
        dashboard(startDate : $startDate, endDate: $endDate) {
          totalEcgRecorded
          noActionCount
          urgentActionCount
          actionRequiredCount
          averageReportTimeDifference
          organizations {
            name
            totalECGCount
            actionRequiredCount
            urgentActionCount
            lastMonthCount
            currentMonthCount
            distanceFromHub
            hubList {
              hubId
              totalECGCount
              noActionCount
              urgentActionCount
              actionRequiredCount
            }
          }
          graphData {
            actionRequiredCount
            ecgTakenCount
            label
            sentForReporting
            reportedBySelfCount
            reportedByPartnerDoctorCount
          }

          patientInformation {
            totalCount
            maleCount
            femaleCount
            otherCount
            age0_18Count
            age18_25Count
            age25_35Count
            age35_55Count
            age55__Count
          }
        }
      }
    `;
    return this._apollo
      .query<any>({
        query: GET_DASHBOARD,
        variables: {
          startDate: startDate,
          endDate: endDate
        }
      })
      .pipe(
        map((res) => {
          const dashboard = new Dashboard(res.data.dashboard);
          return dashboard;
        })
      )
      .pipe(catchError(this.handleError));
  }

  hubECGDeviceLocationDetails(): Observable<EcgDeviceLocationDetail[]> {
    const HUB_ECG_DEVICE_LOCATION_DETAIL = gql`
    query{
      hubECGDeviceLocationDetails
        {
          actionRequiredCount
          urgentActionCount
          longitude
          latitude
          organizationId
          hubId
        }
      }`

    return this._apollo
      .query<any>(
        { query: HUB_ECG_DEVICE_LOCATION_DETAIL }
      ).pipe(
        map((res) => res.data.hubECGDeviceLocationDetails
          .map((x: Partial<EcgDeviceLocationDetail>) => {
            return new EcgDeviceLocationDetail(x.entityId!, x)
          })
        )).pipe(catchError(this.handleError))
  }

  getOrganizations(startDate?: string, endDate?: string): Observable<Organization[]> {

    var variables = {
      gte: '',
      lte: '',
    }
    var query = ''

    if (startDate && endDate) {
      variables.gte = startDate
      variables.lte = endDate
      query = `query GetOrganizations($gte: DateTime, $lte: DateTime){
      organization(where: {createdAt:{gte : $gte,
        lte: $lte}}) {
        organizationId
        name
        createdAt distanceFromHub
      }
    }`
    } else {
      query = `query{ organization {
        organizationId
        name
        createdAt distanceFromHub
        }
      }`
    }

    const ORG_DATA = gql`${query}`
    return this._apollo
      .query<any>({
        query: ORG_DATA,
        variables: variables
      }).pipe(
        map((res) => {
          return res.data.organization
        })
      )
  }

  getReadTokenForJSPDF(): Observable<SASToken> {
    return this._http
      .get<SASToken>(this.pathAPI + 'Storage/GetReadTokenForJSPDF', super.headers())
      .pipe(map((res) => new SASToken(res)))
      .pipe(catchError(this.handleError))
  }
}
