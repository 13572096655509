import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BaseService } from 'src/app/shared/based.service';
import { StorageService } from 'src/app/shared/storage/storage.service';
import { SASToken } from '../domain/entities/sas';
import { environment } from 'src/environments/environment';
import { ITokenService } from '../domain/services/itoken.service';

@Injectable()
export class TokenService extends BaseService implements ITokenService {
  constructor(private http: HttpClient, _storageService: StorageService) {
    super(_storageService);
  }

  // token for signature to read
  getReadTokenForSignature(id: number): Observable<SASToken> {
    return this.http
      .get<SASToken>(
        environment.apiUrl + `Storage/GetReadTokenForSignature/${id}`,
        super.headers()
      )
      .pipe(map((res) => new SASToken(res)))
      .pipe(catchError(this.handleError));
  }
  getReadTokenForECG(fileName: string): Observable<SASToken> {
    return this.http
      .get<SASToken>(
        environment.apiUrl + `Storage/GetReadTokenForECG/${fileName}`,
        super.headers()
      )
      .pipe(map((res) => new SASToken(res)))
      .pipe(catchError(this.handleError));
  }

  getReadTokenForOrganizationLogo(): Observable<SASToken> {
    return this.http
      .get<SASToken>(
        environment.apiUrl + `Storage/GetReadTokenForOrganisationLogo`,
        super.headers()
      )
      .pipe(map((res) => new SASToken(res)))
      .pipe(catchError(this.handleError));
  }
}
