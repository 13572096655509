import { Entity } from '../abstract/entity';

export class Organization extends Entity {
  constructor(entityId: number, obj?: Partial<Organization>) {
    super(entityId);
    if (obj) {
      Object.assign(this, obj);
    }
  }

  name?: string;
  email?: string;
  phoneNumber?: string;
  countryCode?: number
  registeredNumber?: string;
  street?: string;
  city?: string;
  state?: string;
  country?: string;
  totalECGCount?: number;
  noActionCount?: number;
  urgentActionCount?: number;
  actionRequiredCount?: number;
  hubId?: string;
  stemiPreference?: boolean
  firstName?: string
  printAi?: boolean

  get formattedPhoneNumber(): string {
    if (this.phoneNumber) {
      return '+' + this.countryCode + '.' + this.phoneNumber?.substring(0, 5) + '.' + this.phoneNumber?.substring(5);
    } else {
      return '';
    }
  }
}
